import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CustomersService } from '../services/customers.service';
import {
  loadCustomers,
  loadCustomersSuccess,
  loadCustomersFailure,
  createCustomer,
  createCustomerSuccess,
  createCustomerFailure,
  updateCustomer,
  updateCustomerSuccess,
  updateCustomerFailure,
  deleteCustomer,
  deleteCustomerSuccess,
  deleteCustomerFailure
} from '../actions/customers.actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class CustomersEffects {
  loadCustomers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCustomers),
      mergeMap(() =>
        this.customersService.getCustomers().pipe(
          map(customers => {
            return loadCustomersSuccess({ customers });
          }),
          catchError(error => of(loadCustomersFailure({ error })))
        )
      )
    )
  );

  createCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createCustomer),
      mergeMap(action =>
        this.customersService.createCustomer(action.customer).pipe(
          map(customer => createCustomerSuccess({ customer })),
          catchError(error => of(createCustomerFailure({ error })))
        )
      )
    )
  );

  updateCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCustomer),
      mergeMap(action =>
        this.customersService.updateCustomer(action.customer).pipe(
          map(customer => updateCustomerSuccess({ customer })),
          catchError(error => of(updateCustomerFailure({ error })))
        )
      )
    )
  );

  deleteCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteCustomer),
      mergeMap(action =>
        this.customersService.deleteCustomer(action.customerId).pipe(
          map(() => deleteCustomerSuccess({ customerId: action.customerId })),
          catchError(error => of(deleteCustomerFailure({ error })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private customersService: CustomersService) {}
}