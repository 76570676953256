import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SitesService } from '../services/sites.service';
import {
  loadSites,
  loadSitesSuccess,
  loadSitesFailure,
  loadAllSites,
  loadAllSitesSuccess,
  loadAllSitesFailure,
  createSite,
  createSiteSuccess,
  createSiteFailure,
  updateSite,
  updateSiteSuccess,
  updateSiteFailure,
  deleteSite,
  deleteSiteSuccess,
  deleteSiteFailure
} from '../actions/sites.actions';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectSitesLoaded } from '../selectors/sites.selectors';

@Injectable()
export class SitesEffects {
  loadSites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSites),
//       withLatestFrom(this.store.pipe(select(selectSitesLoaded))),
//       mergeMap(([action, loadedPages]) => {
//         if(loadedPages[action.pageNo]) return of();
//         else return this.sitesService.getSites(action.pageNo, action.pageSize).pipe(
//           map(sites => {
//             console.log('Effect: API response', sites);
//             return loadSitesSuccess({ sites, pageNo: action.pageNo });
//           }),
//           catchError(error => {
//             console.error('Effect: API error', error);
//             return of(loadSitesFailure({ error }));
//           })
//         );
// })
      mergeMap(action =>
        this.sitesService.getSites(action.pageNo, action.pageSize).pipe(
          map(sites => loadSitesSuccess({ sites, pageNo: action.pageNo })),
          catchError(error => of(loadSitesFailure({ error })))
        )
      )
    )
  );

  loadAllSites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAllSites),
      mergeMap(action =>
        this.sitesService.getAllSites().pipe(
          map(sites => loadAllSitesSuccess({ sites })),
          catchError(error => of(loadAllSitesFailure({ error })))
        )
      )
    )
  );

  createSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createSite),
      mergeMap(action =>
        this.sitesService.createSite(action.site).pipe(
          map(site => createSiteSuccess({ site })),
          catchError(error => of(createSiteFailure({ error })))
        )
      )
    )
  );

  updateSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSite),
      mergeMap(action =>
        this.sitesService.updateSite(action.site).pipe(
          map(site => updateSiteSuccess({ site })),
          catchError(error => of(updateSiteFailure({ error })))
        )
      )
    )
  );

  deleteSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteSite),
      mergeMap(action =>
        this.sitesService.deleteSite(action.siteId).pipe(
          map(() => deleteSiteSuccess({ siteId: action.siteId })),
          catchError(error => of(deleteSiteFailure({ error })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private sitesService: SitesService, private store: Store) {}
}