import { ActionReducerMap } from '@ngrx/store';
import { customersReducer, CustomersState } from './customers.reducer';
import { sitesReducer, SitesState } from './sites.reducer';

export interface AppState {
  customers: CustomersState;
  sites: SitesState;
}

export const reducers: ActionReducerMap<AppState> = {
  customers: customersReducer,
  sites: sitesReducer,
};