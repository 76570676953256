import { createAction, props } from '@ngrx/store';
import { Customer } from '../models/customer.model';

export const loadCustomers = createAction('[Customers] Load Customers');
export const loadCustomersSuccess = createAction(
  '[Customers] Load Customers Success',
  props<{ customers: Customer[] }>()
);
export const loadCustomersFailure = createAction(
  '[Customers] Load Customers Failure',
  props<{ error: any }>()
);

export const createCustomer = createAction(
  '[Customers] Create Customer',
  props<{ customer: Customer }>()
);
export const createCustomerSuccess = createAction(
  '[Customers] Create Customer Success',
  props<{ customer: Customer }>()
);
export const createCustomerFailure = createAction(
  '[Customers] Create Customer Failure',
  props<{ error: any }>()
);

export const updateCustomer = createAction(
  '[Customers] Update Customer',
  props<{ customer: Customer }>()
);
export const updateCustomerSuccess = createAction(
  '[Customers] Update Customer Success',
  props<{ customer: Customer }>()
);
export const updateCustomerFailure = createAction(
  '[Customers] Update Customer Failure',
  props<{ error: any }>()
);

export const deleteCustomer = createAction(
  '[Customers] Delete Customer',
  props<{ customerId: number }>()
);
export const deleteCustomerSuccess = createAction(
  '[Customers] Delete Customer Success',
  props<{ customerId: number }>()
);
export const deleteCustomerFailure = createAction(
  '[Customers] Delete Customer Failure',
  props<{ error: any }>()
);