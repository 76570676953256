import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./pages/components/home/home.component";
import { RegisterComponent } from "./pages/components/register/register.component";

const routes: Routes = [
  {
    path: "home",
    component: HomeComponent,
    pathMatch: "full",
    data: { breadcrumb: "Home" },
  },
  {
    path: "register",
    component: RegisterComponent,
    pathMatch: "full",
    data: { breadcrumb: "Register" },
  },
  {
    path: "",
    loadChildren: () =>
      import("./pages/pages.module").then(mod => mod.PagesModule),
    data: { breadcrumb: { skip: true } },
  },
  { path: "**", redirectTo: "404" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
