import { environment } from "../../../environments/environment";

export class SiteManagement {
  private baseUrl: string = environment.apiURL;
  private serviceUrl: string = this.baseUrl + "/sitemanagement";

  dashboardSiteDetails = () => `${this.serviceUrl}/dashboard`;
  sites = (includeAll = false) => {
    let url = `${this.serviceUrl}/Site/siteDropdown`;
    if(includeAll) url += `?includeAll=${includeAll}`;
    return url;
  };
  sitelocations = () => `${this.serviceUrl}/dashboard/sitelocations`;
  siteDetailById = (id: number) => `${this.serviceUrl}/Overview/${id}`;
  sitesByCustomerId = (customerId: number) =>
    `${this.serviceUrl}/Site/${customerId}`;
  siteOverviewMenu = (
    pageNumber: number,
    pageSize: number,
    searchText?: string
  ) => {
    let url = `${this.serviceUrl}/Overview/menuDetails/${pageNumber}/${pageSize}`;
    if (searchText) {
      const searchString = encodeURIComponent(searchText);
      url += `?searchString=${searchString}`;
    }
    return url;
  };
  customerDetailsById = (id: number) => `${this.serviceUrl}/Customer/${id}`;
  siteGeneralDetails = (siteId: number) =>
    `${this.serviceUrl}/Site/site/general/${siteId}`;
  siteEnvParams = (customerId: number) =>
    `${this.serviceUrl}/Site/envParams/${customerId}`;
  siteEnvironmentOptions = () => `${this.serviceUrl}/Site/site/environments`;
  siteEnvironment = (siteId: number) =>
    `${this.serviceUrl}/Site/site/environment/${siteId}`;
  siteSubstations = (siteId: number) =>
    `${this.serviceUrl}/Site/substations/${siteId}`;
  allSites = () => `${this.serviceUrl}/Site`;
  updatecustomer = (contactuserId = 0) =>
    `${this.serviceUrl}/Customer/contactUserId?contactUserId=${contactuserId}`;
  saveSite = () => `${this.serviceUrl}/Site/site`;
}
