import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { UtilsService } from '../../helpers/utils.service';
import { AccessControlService } from '../../services/access-control.service';
import { Customer } from '../models/customer.model';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {
  private baseUrl = `${environment.apiURL}/customers`;

  constructor(
    private http: HttpClient,
    private utilsService: UtilsService,
    private accessContolService: AccessControlService) {}

  getCustomers(): Observable<Customer[]> {
    return this.utilsService
    .httpGet(this.accessContolService.adminSettings())
    .pipe(map(response => response.customers || []));
  }

  createCustomer(customer: Customer): Observable<Customer> {
    return this.http.post<Customer>(this.baseUrl, customer);
  }

  updateCustomer(customer: Customer): Observable<Customer> {
    return this.http.put<Customer>(`${this.baseUrl}/${customer.id}`, customer);
  }

  deleteCustomer(customerId: number): Observable<Customer> {
    return this.http.delete<Customer>(`${this.baseUrl}/${customerId}`);
  }
}