export const environment = {
    production: false,
    title: 'Local Environment',
    apiURL: 'https://dev.ionecloud.lntebg.com',
    AzureSubKey: 'tCrPOavrerghku-CTp5xzMBBnQ3VT94chqRz1EVb_vQ',
    firebase: {
        apiKey: "AIzaSyAozpHh2rEqcHr9T81oILVB_WyKilyhMNk",
        authDomain: "ionecloud-push.firebaseapp.com",
        projectId: "ionecloud-push",
        storageBucket: "ionecloud-push.appspot.com",
        messagingSenderId: "1027231183031",
        appId: "1:1027231183031:web:f03ea3494f84bf0b2d1d32",
        measurementId: "G-ND6186H9CK",
        vapidKey: "BMwfMPwc9YmAyhRm0ELmH7uFTJXuiCp1Ywiw2-RPZDB2JduBQBj_y-yHdPyoxoHeuSEdjXws7DFeh0T1VaDwhv8"
    }
};