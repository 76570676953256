import { Component, inject } from "@angular/core";
import { AuthenticationService } from "./core/helpers/authentication.service";
import { Router } from "@angular/router";
import { BreadcrumbService } from "./shared/services/breadcrumb.service";

export enum Operation {
  None,
  StoreCode,
  ShowLoginPage,
  ExchangeCode,
  Local,
  Register,
}

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
})
export class AppComponent {
  title = "SmartComm";

  breadcrumbService: BreadcrumbService = inject(BreadcrumbService);
  authService: AuthenticationService = inject(AuthenticationService);
  router: Router = inject(Router);

  ngOnInit(): void {
    this.loadApp();
  }

  loadApp() {
    console.log("start on the app");
    const url = new URL(window.location.href);
    const code = url.searchParams.get("code");
    if (!this.authService.checkAuthentication()) {
      if (window.location.href.includes("register")) {
        this.getRegisterPage();
      } else if (window.location.href.includes("localhost")) {
        console.log("app loaded on localhost");
        localStorage.clear();
        const token =
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyU2Vzc2lvbkNvbnRleHQiOiJ7XCJVc2VySWRlbnRpdHlDb250ZXh0XCI6e1wiVXNlcklkXCI6MjM5LFwiRmlyc3ROYW1lXCI6XCJTdXBlckFkbWluXCIsXCJhY3RpdmVcIjp0cnVlLFwiTGFzdE5hbWVcIjpcIlVBVFwiLFwiTW9iaWxlTnVtYmVyXCI6XCI5ODIwNjU5NjE2XCIsXCJFbWFpbEFkZHJlc3NcIjpcInNtYXJ0Y29tbXVhdEBnbWFpbC5jb21cIixcIkRpc3BsYXlOYW1lXCI6XCJTdXBlckFkbWluVUFUXCIsXCJIYXNBZ3JlZW1lbnRTaWduZWRcIjp0cnVlLFwiSXNBY3RpdmVcIjp0cnVlLFwiQWN0aXZhdGlvbkxpbmtcIjpcIlwiLFwiUm9sZUlkXCI6MjYyLFwiRmVkZXJhdGVkSWRcIjpcImdiMDBjZDNlLTBhYzgtN2E5YS1iNjExLTdmNmY0NzNhZDM3MlwiLFwiQ29tcGFueUlkXCI6bnVsbCxcIkNvbXBhbnlOYW1lXCI6bnVsbCxcIlJvbGVOYW1lXCI6XCJFQkcuU3VwZXJBZG1pblwiLFwiVGltZVpvbmVJZFwiOlwiSW5kaWEgU3RhbmRhcmQgVGltZVwiLFwiSXNDdXN0QWRtaW5cIjpmYWxzZSxcIklzU3VwZXJBZG1pblwiOnRydWV9LFwiTGljZW5zZUNvbnRleHRcIjpudWxsfSIsIm5iZiI6MTczNzY5NTY3MywiZXhwIjoxNzM3Njk3NDczLCJpc3MiOiJpT25lQ2xvdWQiLCJhdWQiOiJEMW9RbmxrUEZkZ1ZSSlpNNmF4THBLV3k2VDA0dTdMVyJ9.mPIqBoQgsMs4hXf85YSxk1hIziPrlbBJXiTdz_x6Myw";
        this.authService.setTokenOnLocal(token);
        // on localhost do nothing
      } else if (code) {
        console.log("got storecode from url");
        this.authService.getToken(code);
      } else {
        console.log("got nothing");
        this.authService.login();
      }
    } else {
      console.log("user logged in");
    }
  }
  getRegisterPage() {
    if (this.authService.checkAuthentication()) {
      this.router.navigate(["/layout/dashboard"]);
    } else {
      this.router.navigate(["/register"]);
    }
  }
}
