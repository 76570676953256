import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '../helpers/utils.service';
import { catchError, map } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AccessControlService {

    private baseUrl: string = environment.apiURL;
    private serviceUrl: string = this.baseUrl + '/accesscontrol';

    constructor(
        private httpService: HttpClient,
        private utilsService: UtilsService
    ) { }

    token = () => `${this.serviceUrl}/Token`;
    logout = () => `${this.serviceUrl}/Account/logoutUrl/`;
    accountDetails = () => `${this.serviceUrl}/Account`;

    notificationMaster = () => `${this.serviceUrl}/GeneralSettings/notificationMaster`;
    notificationData = (customerId: number) => `${this.serviceUrl}/GeneralSettings/notification/${customerId}`;
    pushNotificationStatus = (userId: number) => `${this.serviceUrl}/GeneralSettings/pushNotification/${userId}`;
    unitData = (customerId: number) => `${this.serviceUrl}/GeneralSettings/measurementUnits/${customerId}`;
    postGeneralSettings = (customerId: number) => `${this.serviceUrl}/GeneralSettings/generalSettings/${customerId}`;
    updatePushNotification = (userId: number, isEnabled: boolean) => `${this.serviceUrl}/GeneralSettings/pushNotificationUpdate/${userId}/${isEnabled}`;
    updateViewPreference = (isDarkMode: boolean) => `${this.serviceUrl}/GeneralSettings/generalSettings/darkMode/${isDarkMode}`;

    userDetails = (userId: number) => `${this.serviceUrl}/UserProfile/UserProfileView/${userId}`;
    adminSettings = () => `${this.serviceUrl}/GeneralSettings/adminSettings`;
    roles = (customerId: number) => `${this.serviceUrl}/EntityAccess/roles/${customerId}`;
    entityMaster = (customerId: number) => `${this.serviceUrl}/EntityAccess/entityMaster/${customerId}`;
    entityRoles = (roleId: number) => `${this.serviceUrl}/EntityAccess/entityRoles/${roleId}`;
    usersList = (customerId: number) => `${this.serviceUrl}/Account/${customerId}`;
    saveRoles = () => `${this.serviceUrl}/EntityAccess/roles`;
    deleteRole = (roleId: number) => `${this.serviceUrl}/EntityAccess/roles/${roleId}`;
    entityAccess = () => `${this.serviceUrl}/EntityAccess/entityRole`;
}