import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-right-slide-out-panel",
  templateUrl: "./right-slide-out-panel.component.html",
  styleUrl: "./right-slide-out-panel.component.scss",
})
export class RightSlideOutPanelComponent {
  constructor(
    public dialogRef: MatDialogRef<RightSlideOutPanelComponent>,
    @Inject(MAT_DIALOG_DATA) public matDialogData: any
  ) {
    // console.log(this.matDialogData);
  }
}
