import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { UtilsService } from '../../helpers/utils.service';
import { Site, SiteDetail } from '../models/site.model';
import { SiteManagement } from '../../constants/site-management';

@Injectable({
  providedIn: 'root'
})
export class SitesService {
  private baseUrl = `${environment.apiURL}/sites`;
  private siteManagement = new SiteManagement();

  constructor(
    private http: HttpClient,
    private utilsService: UtilsService) {}

  getSites(pageNo:number, pageSize: number): Observable<Site[]> {
    return this.utilsService
    .httpGet(this.siteManagement.siteOverviewMenu(pageNo, pageSize))
    .pipe(map(response => response.siteOverview || []));
  }

  getAllSites(): Observable<SiteDetail[]> {
    return this.utilsService
    .httpGet(this.siteManagement.allSites())
    .pipe(map(response => response || []));
  }

  createSite(site: Site): Observable<Site> {
    return this.http.post<Site>(this.baseUrl, site);
  }

  updateSite(site: Site): Observable<Site> {
    return this.http.put<Site>(`${this.baseUrl}/${site.siteId}`, site);
  }

  deleteSite(siteId: number): Observable<Site> {
    return this.http.delete<Site>(`${this.baseUrl}/${siteId}`);
  }
}