import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  loadSites,
  loadSitesSuccess,
  loadSitesFailure,
  createSite,
  createSiteSuccess,
  createSiteFailure,
  updateSite,
  updateSiteSuccess,
  updateSiteFailure,
  deleteSite,
  deleteSiteSuccess,
  deleteSiteFailure,
  loadAllSites,
  loadAllSitesSuccess,
  loadAllSitesFailure
} from '../actions/sites.actions';
import { Site, SiteDetail } from '../models/site.model';
import { logout } from '../actions/auth.actions';

export interface SitesState extends EntityState<Site> {
  loading: boolean;
  loadedPages: { [pageNo: number]: Site[] };
  error: any;
  allSites: SiteDetail[];
}

export const adapter: EntityAdapter<Site> = createEntityAdapter<Site>({
  selectId: (site: Site) => site.siteId // Custom selectId implementation
});

export const initialState: SitesState = adapter.getInitialState({
  loading: false,
  loadedPages: {},
  error: null,
  allSites: [],
});

const _sitesReducer = createReducer(
  initialState,
  on(loadSites, state => ({ ...state, loading: true })),
  on(loadSitesSuccess, (state, { sites, pageNo }) => {
    const updatedLoadedPages = { ...state.loadedPages, [pageNo]: sites };
    const allSites = Object.values(updatedLoadedPages).flat();
    return adapter.setAll(allSites, { ...state, loading: false, loadedPages: updatedLoadedPages });
  }),
  on(loadSitesFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(loadAllSitesSuccess, (state, { sites }) => ({
    ...state,
    allSites: sites
  })),
  on(loadAllSitesFailure, (state, { error }) => ({ ...state, error })),
  on(createSite, state => ({ ...state, loading: true })),
  on(createSiteSuccess, (state, { site }) => adapter.addOne(site, { ...state, loading: false })),
  on(createSiteFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(updateSite, state => ({ ...state, loading: true })),
  on(updateSiteSuccess, (state, { site }) => adapter.updateOne({ id: site.siteId, changes: site }, { ...state, loading: false })),
  on(updateSiteFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(deleteSite, state => ({ ...state, loading: true })),
  on(deleteSiteSuccess, (state, { siteId }) => adapter.removeOne(siteId, { ...state, loading: false })),
  on(deleteSiteFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(logout, state => initialState)
);

export function sitesReducer(state: any, action: any) {
  return _sitesReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors();