export interface TreeData {
    index: string;
    id: number;
    name: string;
    children?: TreeData[];
    routelink?: string;
}

export enum SnackBarTypeEnum {
    Info = 0,
    Success = 1,
    Failure = 2,
    Reminder = 3,
    Warning = 4,
    Error = 5,
}