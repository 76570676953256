import { createAction, props } from '@ngrx/store';
import { Site, SiteDetail } from '../models/site.model';

export const loadSites = createAction('[Sites] Load Sites',
    props<{ pageNo: number; pageSize: number }>()
);
export const loadSitesSuccess = createAction(
  '[Sites] Load Sites Success',
  props<{ sites: Site[], pageNo: number }>()
);
export const loadSitesFailure = createAction(
  '[Sites] Load Sites Failure',
  props<{ error: any }>()
);

export const loadAllSites = createAction('[Sites] Load All Sites');
export const loadAllSitesSuccess = createAction('[Sites] Load All Sites Success', props<{ sites: SiteDetail[] }>());
export const loadAllSitesFailure = createAction('[Sites] Load All Sites Failure', props<{ error: any }>());

export const createSite = createAction(
  '[Sites] Create Site',
  props<{ site: Site }>()
);
export const createSiteSuccess = createAction(
  '[Sites] Create Site Success',
  props<{ site: Site }>()
);
export const createSiteFailure = createAction(
  '[Sites] Create Site Failure',
  props<{ error: any }>()
);

export const updateSite = createAction(
  '[Sites] Update Site',
  props<{ site: Site }>()
);
export const updateSiteSuccess = createAction(
  '[Sites] Update Site Success',
  props<{ site: Site }>()
);
export const updateSiteFailure = createAction(
  '[Sites] Update Site Failure',
  props<{ error: any }>()
);

export const deleteSite = createAction(
  '[Sites] Delete Site',
  props<{ siteId: number }>()
);
export const deleteSiteSuccess = createAction(
  '[Sites] Delete Site Success',
  props<{ siteId: number }>()
);
export const deleteSiteFailure = createAction(
  '[Sites] Delete Site Failure',
  props<{ error: any }>()
);