import { HttpInterceptorFn } from "@angular/common/http";
import { AuthenticationService } from "../helpers/authentication.service";
import { inject } from "@angular/core";

export const jwtInterceptor: HttpInterceptorFn = (request, next) => {
  const authenticationService = inject(AuthenticationService);
  const token = authenticationService.getAccessToken();
  if (token) {
    request = request.clone({
      headers: request.headers.set("Authorization", `Bearer ${token}`),
    });
  }
  return next(request);
};
