import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BreadcrumbService {

    private router: Router = inject(Router)
    private activatedRoute: ActivatedRoute = inject(ActivatedRoute)

    breadcrumbs: BehaviorSubject<Array<{ label: string, url: string }>> = new BehaviorSubject([]);

    constructor() {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            this.breadcrumbs.next(this.createBreadcrumbs(this.activatedRoute.root));
        });
    }

    private createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: Array<{ label: string, url: string }> = []): Array<{ label: string, url: string }> {
        const children: ActivatedRoute[] = route.children;

        if (children.length === 0) {
            return breadcrumbs;
        }

        for (const child of children) {
            const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
            if (routeURL !== '') {
                url += `/${routeURL}`;
            }

            if (child.snapshot.data['breadcrumb'] !== 'skip')
                breadcrumbs.push({ label: child.snapshot.data['breadcrumb'], url: url });
            return this.createBreadcrumbs(child, url, breadcrumbs);
        }

        return breadcrumbs;
    }
}
