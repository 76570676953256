import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  loadCustomers,
  loadCustomersSuccess,
  loadCustomersFailure,
  createCustomer,
  createCustomerSuccess,
  createCustomerFailure,
  updateCustomer,
  updateCustomerSuccess,
  updateCustomerFailure,
  deleteCustomer,
  deleteCustomerSuccess,
  deleteCustomerFailure
} from '../actions/customers.actions';
import { Customer } from '../models/customer.model';
import { logout } from '../actions/auth.actions';

export interface CustomersState extends EntityState<Customer> {
  loading: boolean;
  loaded: boolean;
  error: any;
}

export const adapter: EntityAdapter<Customer> = createEntityAdapter<Customer>();

export const initialState: CustomersState = adapter.getInitialState({
  loading: false,
  loaded: false,
  error: null,
});

const _customersReducer = createReducer(
  initialState,
  on(loadCustomers, state => ({ ...state, loading: true })),
  on(loadCustomersSuccess, (state, { customers }) => {  
    return adapter.setAll(customers, { ...state, loading: false })}),
  on(loadCustomersFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(createCustomer, state => ({ ...state, loading: true })),
  on(createCustomerSuccess, (state, { customer }) => adapter.addOne(customer, { ...state, loading: false })),
  on(createCustomerFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(updateCustomer, state => ({ ...state, loading: true })),
  on(updateCustomerSuccess, (state, { customer }) => adapter.updateOne({ id: customer.id, changes: customer }, { ...state, loading: false })),
  on(updateCustomerFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(deleteCustomer, state => ({ ...state, loading: true })),
  on(deleteCustomerSuccess, (state, { customerId }) => adapter.removeOne(customerId, { ...state, loading: false })),
  on(deleteCustomerFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(logout, state => initialState)
);

export function customersReducer(state: any, action: any) {
  return _customersReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();