import { inject, Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { RightSlideOutPanelComponent } from '../../pages/common/right-slide-out-panel/right-slide-out-panel.component';
import { SnackBarComponent } from '../components/snack-bar/snack-bar.component';

@Injectable({
    providedIn: 'root'
})
export class SharedService {

    private router: Router = inject(Router);
    private route: ActivatedRoute = inject(ActivatedRoute);
    private snackBar: MatSnackBar = inject(MatSnackBar);
    slideOutDialog: MatDialog = inject(MatDialog);

    summaryView: boolean = false;

    private leftNav = new BehaviorSubject<boolean>(true);

    toggleSubMenu: boolean = true;
    // toggleSubMenuUser: boolean = false;
    private subMenuDMM = new BehaviorSubject<boolean>(this.toggleSubMenu);
    // private subMenuUser = new BehaviorSubject<boolean>(this.toggleSubMenuUser)


    adminPanel: boolean = true;
    public subMenuAdminPanel = new BehaviorSubject<boolean>(this.adminPanel);

    sites: boolean = true;
    private subMenuSites = new BehaviorSubject<boolean>(this.sites);

    digitalLogBook: boolean = true;
    private subMenuDLB = new BehaviorSubject<boolean>(this.digitalLogBook);

    sitePanel: boolean = false;
    private subSitePanel = new BehaviorSubject<boolean>(this.sitePanel);

    settingsMenu: boolean = true;
    private subMenuSettings = new BehaviorSubject<boolean>(this.settingsMenu);

    private readonly _adminSettings = new BehaviorSubject<any>(null);

    private _modules = new BehaviorSubject<any>({} as any);

    private readonly _loadPermissions = new BehaviorSubject<boolean>(false);
    set loadPermissions(value: any) {
        this._loadPermissions.next(value)
    }
    get loadPermissions() {
        return this._loadPermissions.getValue();
    }
    permissionsLoaded: Observable<any> = this._loadPermissions.asObservable();

    // Observable for left nav open and close

    notifyAction(action: boolean) {
        this.leftNav.next(action);
    }

    getAction(): Observable<boolean> {
        return this.leftNav.asObservable();
    }

    get isOandM() {
        return this.user && this.user.role.name === 'EA.OandM';
    }

    toggleDMMSubMenu() {
        this.toggleSubMenu = !this.toggleSubMenu;
        this.subMenuDMM.next(this.toggleSubMenu);
    }

    getToggleSubMenu() {
        return this.subMenuDMM.asObservable();
    }

    toggleAdminPanelSubMenu() {
        this.adminPanel = !this.adminPanel
        this.subMenuAdminPanel.next(this.adminPanel);
    }

    toggleSitesSubMenu(tab?: string) {
        if (tab == 'assets') {
            this.sites = false;
        } else {
            this.sites = !this.sites;
        }
        this.subMenuSites.next(this.sites);
    }

    getToggleAdminPanelSubMenu() {
        return this.subMenuAdminPanel.asObservable();
    }

    getToggleSitesSubMenu() {
        return this.subMenuSites.asObservable();
    }

    toggleDLBSubMenu() {
        this.digitalLogBook = !this.digitalLogBook;
        this.subMenuDLB.next(this.digitalLogBook);
    }

    getToggleSubMenuDLB() {
        return this.subMenuDLB.asObservable();
    }

    toggleSettingsSubMenu() {
        this.settingsMenu = !this.settingsMenu;
        this.subMenuSettings.next(this.settingsMenu);
    }

    getToggleSubMenuSettings() {
        return this.subMenuSettings.asObservable();
    }

    toggleSitePanel() {
        this.sitePanel = !this.sitePanel;
        this.subSitePanel.next(this.sitePanel);
    }

    getToggleSitePanel() {
        return this.subSitePanel.asObservable();
    }

    get adminSettings() {
        return this._adminSettings.getValue()
    }

    set adminSettings(value: any) {
        this._adminSettings.next(value);
    }

    addAdminSettings(value: any) {
        this.adminSettings = value;
    }

    getAdminSettings() {
        return this._adminSettings.asObservable();
    }

    get modules() {
        return this._modules.getValue();
    }

    set modules(value: any) {
        this._modules.next(value)
    }

    addModule(data: any) {
        this.modules = data;
    }

    getModule() {
        return this._modules.asObservable();
    }

    // showSnackBar(
    //     message: string,
    //     type?: string,
    //     action?: string,
    //     config: MatSnackBarConfig = {
    //         horizontalPosition: 'right',
    //         verticalPosition: 'top',
    //     }
    // ): void {
    //     this.snackBar.open(message, action, {
    //         ...config,
    //         duration: action ? undefined : 3000,
    //         panelClass: ['alertMsg'],
    //     });
    // }

    customerIdforDLB: number = 0;
    _message: string = '';

    setCustomerIdDLB(customerid: number): any {
        this.customerIdforDLB = customerid;
    }
    getCustomerIdDLB(): any {
        return this.customerIdforDLB;
    }

    set message(val: string) {
        this._message = val;
    }
    get message(): string {
        const returnedMessage = this._message;
        return returnedMessage;
    }
    clear() {
        this.message = '';
    }


    deviceDetectorService: DeviceDetectorService = inject(DeviceDetectorService);

    isMobile = this.deviceDetectorService.isMobile();
    isTablet = this.deviceDetectorService.isTablet();
    isDesktopDevice = this.deviceDetectorService.isDesktop();
    currentDeviceType = this.deviceDetectorService.deviceType

    isDarkMode = false;

    private readonly _user = new BehaviorSubject<any>({} as any);
    set user(value: any) {
        this._user.next(value)
    }
    get user() {
        return this._user.getValue();
    }
    getUser: Observable<any> = this._user.asObservable();

    customerId: number = 0;
    siteId: number = 0;
    subStationId: number = 0;
    assetId: number = 0;
    subAssetId: number = 0;
    feederNo: string = '';
    offsetDate = (this.user.customer) ? this.user.customer.utcOffset : '';

    stringTrim(string: string) {
        return string.replace(/\s/g, "")
    }

    isEmpty(value) {
        return (
            // null or undefined
            (value == null) || (value == undefined) ||

            // has length and it's zero
            (value.hasOwnProperty('length') && value.length === 0) ||

            // is an Object and has no keys
            (value.constructor === Object && Object.keys(value).length === 0)
        )
    }

    navigateTo(node: any) {
        if (this.router.url === node.routelink) return;
        this.router.navigate([node.routelink]);
    }

    extractAllRouteParams(router: Router): any {
        const params: any = {}
        let route: ActivatedRouteSnapshot | null = router.routerState.snapshot.root
        do {
            Object.keys(route.params).forEach(
                (key) => (params[key] = route?.params[key])
            )
            route = route.firstChild
        } while (route)
        return params
    }

    openSlideOutPanel(module: string, type: string, id = null) {
        this.slideOutDialog.open(RightSlideOutPanelComponent, {
            panelClass: 'slide-out-panel-dialog',
            disableClose: true,
            data: { module, type, id }
        })
    }

    showSnackbar(type: number, message: string) {
        this.snackBar.openFromComponent(SnackBarComponent, {
            data: {
                type,
                message,
            },
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 3000
        });
    }

    convertLocalToUTC(localDate: string): string {
        // const localDateObj = new Date(localDate);
        // const utcDate = localDateObj.toISOString();
        // return utcDate;

        // Create a new Date object from the local date string
        const localDateObj = new Date(localDate);

        // Get the UTC date components
        const year = localDateObj.getUTCFullYear();
        const month = String(localDateObj.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(localDateObj.getUTCDate()).padStart(2, '0');
        const hours = String(localDateObj.getUTCHours()).padStart(2, '0');
        const minutes = String(localDateObj.getUTCMinutes()).padStart(2, '0');
        const seconds = String(localDateObj.getUTCSeconds()).padStart(2, '0');

        // Format the UTC date string
        const utcDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

        return utcDate;
    }

    convertUTCToLocal(utcDate: string): string {
        // const utcDateObj = new Date(utcDate);
        // const localDate = utcDateObj.toLocaleString();
        // return localDate;
        // Create a new Date object from the UTC date string
        const utcDateObj = new Date(utcDate + 'Z'); // Ensure the string is treated as UTC

        // Get the local date components
        const year = utcDateObj.getFullYear();
        const month = String(utcDateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(utcDateObj.getDate()).padStart(2, '0');
        const hours = String(utcDateObj.getHours()).padStart(2, '0');
        const minutes = String(utcDateObj.getMinutes()).padStart(2, '0');
        const seconds = String(utcDateObj.getSeconds()).padStart(2, '0');

        // Format the local date string
        const localDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

        return localDate;
    }

    generateTimeSlots(): string[] {
        const timeSlots: string[] = [];
        const startTime = new Date();
        startTime.setHours(0, 0, 0, 0); // Set to 12:00 AM
      
        for (let i = 0; i < 48; i++) {
          const hours = startTime.getHours();
          const minutes = startTime.getMinutes();
          const period = hours < 12 ? 'AM' : 'PM';
          const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
          const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
          const timeSlot = `${formattedHours}:${formattedMinutes} ${period}`;
          timeSlots.push(timeSlot);
          startTime.setMinutes(startTime.getMinutes() + 30); // Increment by 30 minutes
        }
      
        return timeSlots;
      }

}
