import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { MaterialModule } from '../../modules/material.module';
import { IconComponent } from "../icon/icon.component";
import { CommonModule } from '@angular/common';
import { SnackBarTypeEnum } from '../../../models/config';

@Component({
    selector: 'app-snack-bar',
    standalone: true,
    imports: [CommonModule, MaterialModule, IconComponent],
    templateUrl: './snack-bar.component.html',
    styleUrl: './snack-bar.component.scss'
})
export class SnackBarComponent {

    snackBarStatus: string;

    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: any,
        private snackBarRef: MatSnackBarRef<SnackBarComponent>
    ) {
        this.snackBarStatus = this.getEnumKeyForValue(data.type) + ' !';
    }

    getEnumKeyForValue(value: number): string {
        return SnackBarTypeEnum[value];
    }

    dismiss() {
        this.snackBarRef.dismiss();
    }
}
