<div class="custom-snackbar" [ngClass]="snackBarStatus">
    <span class="snackbar-icon-content">
        <app-icon action="snackbar"></app-icon>
        <span class="snackbar-content">
            <div class="snackbar-msg">{{ snackBarStatus.toUpperCase() }}</div>
            <div class="snackbar-desc">{{ data.message }}</div>
        </span>
    </span>
    <span class="snackbar-close" (click)="dismiss()">
        <mat-icon>close</mat-icon>
    </span>
</div>